/**
* Theme: Hyper - Responsive Bootstrap 5 Admin Dashboard
* Author: Coderthemes
* Module/App: Main Js
*/

import * as bootstrap from "expose-loader?exposes=bootstrap!bootstrap"



// Bootstrap Components
function initComponents() {

     // loader - Preloader
     $(window).on('load', function () {
          $('#status').fadeOut();
          $('#preloader').delay(350).fadeOut('slow');
     });

     // Popovers
     const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
     const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl))

     // Tooltips
     const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
     const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))

     // offcanvas
     const offcanvasElementList = document.querySelectorAll('.offcanvas')
     const offcanvasList = [...offcanvasElementList].map(offcanvasEl => new bootstrap.Offcanvas(offcanvasEl))

     //Toasts
     var toastPlacement = document.getElementById("toastPlacement");
     if (toastPlacement) {
          document.getElementById("selectToastPlacement").addEventListener("change", function () {
               if (!toastPlacement.dataset.originalClass) {
                    toastPlacement.dataset.originalClass = toastPlacement.className;
               }
               toastPlacement.className = toastPlacement.dataset.originalClass + " " + this.value;
          });
     }

     var toastElList = [].slice.call(document.querySelectorAll('.toast'))
     var toastList = toastElList.map(function (toastEl) {
          return new bootstrap.Toast(toastEl)
     })

     // Bootstrap Alert Live Example
     const alertPlaceholder = document.getElementById('liveAlertPlaceholder')
     const alert = (message, type) => {
          const wrapper = document.createElement('div')
          wrapper.innerHTML = [
               `<div class="alert alert-${type} alert-dismissible" role="alert">`,
               `   <div>${message}</div>`,
               '   <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>',
               '</div>'
          ].join('')

          alertPlaceholder.append(wrapper)
     }

     const alertTrigger = document.getElementById('liveAlertBtn')
     if (alertTrigger) {
          alertTrigger.addEventListener('click', () => {
               alert('Nice, you triggered this alert message!', 'success')
          })
     }

}

// Portlet Widget (Card Reload, Collapse, and Delete)
function initPortletCard() {

     var portletIdentifier = ".card"
     var portletCloser = '.card a[data-bs-toggle="remove"]'
     var portletRefresher = '.card a[data-bs-toggle="reload"]'
     let self = this

     // Panel closest
     $(document).on("click", portletCloser, function (ev) {
          ev.preventDefault();
          var $portlet = $(this).closest(portletIdentifier);
          var $portlet_parent = $portlet.parent();
          $portlet.remove();
          if ($portlet_parent.children().length == 0) {
               $portlet_parent.remove();
          }
     });

     // Panel Reload
     $(document).on("click", portletRefresher, function (ev) {
          ev.preventDefault();
          var $portlet = $(this).closest(portletIdentifier);
          // This is just a simulation, nothing is going to be reloaded
          $portlet.append('<div class="card-disabled"><div class="card-portlets-loader"></div></div>');
          var $pd = $portlet.find('.card-disabled');
          setTimeout(function () {
               $pd.fadeOut('fast', function () {
                    $pd.remove();
               });
          }, 500 + 300 * (Math.random() * 5));
     });
}

//  Multi Dropdown
function initMultiDropdown() {
     $('.dropdown-menu a.dropdown-toggle').on('click', function () {
          var dropdown = $(this).next('.dropdown-menu');
          var otherDropdown = $(this).parent().parent().find('.dropdown-menu').not(dropdown);
          otherDropdown.removeClass('show')
          otherDropdown.parent().find('.dropdown-toggle').removeClass('show')
          return false;
     });
}

// Left Sidebar Menu (Vertical Menu)
function initLeftSidebar() {
     var self = this;

     if ($(".side-nav").length) {
          var navCollapse = $('.side-nav li .collapse');
          var navToggle = $(".side-nav li [data-bs-toggle='collapse']");
          navToggle.on('click', function (e) {
               return false;
          });

          // open one menu at a time only
          navCollapse.on({
               'show.bs.collapse': function (event) {
                    var parent = $(event.target).parents('.collapse.show');
                    $('.side-nav .collapse.show').not(event.target).not(parent).collapse('hide');
               }
          });

          // activate the menu in left side bar (Vertical Menu) based on url
          $(".side-nav a").each(function () {
               var pageUrl = window.location.href.split(/[?#]/)[0];
               if (this.href == pageUrl) {
                    $(this).addClass("active");
                    $(this).parent().addClass("menuitem-active");
                    $(this).parent().parent().parent().addClass("show");
                    $(this).parent().parent().parent().parent().addClass("menuitem-active"); // add active to li of the current link

                    var firstLevelParent = $(this).parent().parent().parent().parent().parent().parent();
                    if (firstLevelParent.attr('id') !== 'sidebar-menu') firstLevelParent.addClass("show");

                    $(this).parent().parent().parent().parent().parent().parent().parent().addClass("menuitem-active");

                    var secondLevelParent = $(this).parent().parent().parent().parent().parent().parent().parent().parent().parent();
                    if (secondLevelParent.attr('id') !== 'wrapper') secondLevelParent.addClass("show");

                    var upperLevelParent = $(this).parent().parent().parent().parent().parent().parent().parent().parent().parent().parent();
                    if (!upperLevelParent.is('body')) upperLevelParent.addClass("menuitem-active");
               }
          });


          setTimeout(function () {
               var activatedItem = document.querySelector('li.menuitem-active .active');
               if (activatedItem != null) {
                    var simplebarContent = document.querySelector('.leftside-menu .simplebar-content-wrapper');
                    var offset = activatedItem.offsetTop - 300;
                    if (simplebarContent && offset > 100) {
                         scrollTo(simplebarContent, offset, 600);
                    }
               }
          }, 200);

          // scrollTo (Left Side Bar Active Menu)
          function easeInOutQuad(t, b, c, d) {
               t /= d / 2;
               if (t < 1) return c / 2 * t * t + b;
               t--;
               return -c / 2 * (t * (t - 2) - 1) + b;
          }
          function scrollTo(element, to, duration) {
               var start = element.scrollTop, change = to - start, currentTime = 0, increment = 20;
               var animateScroll = function () {
                    currentTime += increment;
                    var val = easeInOutQuad(currentTime, start, change, duration);
                    element.scrollTop = val;
                    if (currentTime < duration) {
                         setTimeout(animateScroll, increment);
                    }
               };
               animateScroll();
          }
     }
}

// Topbar Menu (HOrizontal Menu)
function initTopbarMenu() {
     if ($('.navbar-nav').length) {
          $('.navbar-nav li a').each(function () {
               var pageUrl = window.location.href.split(/[?#]/)[0];
               if (this.href == pageUrl) {
                    $(this).addClass('active');
                    $(this).parent().parent().addClass('active'); // add active to li of the current link
                    $(this).parent().parent().parent().parent().addClass('active');
                    $(this).parent().parent().parent().parent().parent().parent().addClass('active');
               }
          });

          // Topbar - main menu
          $('.navbar-toggle').on('click', function () {
               $(this).toggleClass('open');
               $('#navigation').slideToggle(400);
          });
     }
}

// Topbar Search Form
function initSearch() {
     // Serach Toggle
     var navDropdowns = $('.navbar-custom .dropdown:not(.app-search)');

     // hide on other click
     $(document).on('click', function (e) {
          if (e.target.id == "top-search" || e.target.closest('#search-dropdown')) {
               $('#search-dropdown').addClass('d-block');
          } else {
               $('#search-dropdown').removeClass('d-block');
          }
          return true;
     });

     // Serach Toggle
     $('#top-search').on('focus', function (e) {
          e.preventDefault();
          navDropdowns.children('.dropdown-menu.show').removeClass('show');
          $('#search-dropdown').addClass('d-block');
          return false;
     });

     // hide search on opening other dropdown
     navDropdowns.on('show.bs.dropdown', function () {
          $('#search-dropdown').removeClass('d-block');
     });
}

// Topbar Fullscreen Button
function initfullScreenListener() {
     var self = this;
     var fullScreenBtn = document.querySelector('[data-toggle="fullscreen"]');

     if (fullScreenBtn) {
          fullScreenBtn.addEventListener('click', function (e) {
               e.preventDefault();
               document.body.classList.toggle('fullscreen-enable')
               if (!document.fullscreenElement && /* alternative standard method */ !document.mozFullScreenElement && !document.webkitFullscreenElement) {  // current working methods
                    if (document.documentElement.requestFullscreen) {
                         document.documentElement.requestFullscreen();
                    } else if (document.documentElement.mozRequestFullScreen) {
                         document.documentElement.mozRequestFullScreen();
                    } else if (document.documentElement.webkitRequestFullscreen) {
                         document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
                    }
               } else {
                    if (document.cancelFullScreen) {
                         document.cancelFullScreen();
                    } else if (document.mozCancelFullScreen) {
                         document.mozCancelFullScreen();
                    } else if (document.webkitCancelFullScreen) {
                         document.webkitCancelFullScreen();
                    }
               }
          });
     }
}

// Show/Hide Password
function initShowHidePassword() {
     $("[data-password]").on('click', function () {
          if ($(this).attr('data-password') == "false") {
               $(this).siblings("input").attr("type", "text");
               $(this).attr('data-password', 'true');
               $(this).addClass("show-password");
          } else {
               $(this).siblings("input").attr("type", "password");
               $(this).attr('data-password', 'false');
               $(this).removeClass("show-password");
          }
     });
}

// Form Validation
function initFormValidation() {
     // Example starter JavaScript for disabling form submissions if there are invalid fields
     // Fetch all the forms we want to apply custom Bootstrap validation styles to
     // Loop over them and prevent submission
     document.querySelectorAll('.needs-validation').forEach(form => {
          form.addEventListener('submit', event => {
               if (!form.checkValidity()) {
                    event.preventDefault()
                    event.stopPropagation()
               }

               form.classList.add('was-validated')
          }, false)
     })
}

// Form Advance
function initFormAdvance() {
     // Select2
     if (jQuery().select2) {
          $('[data-toggle="select2"]').select2();
     }

     // Input Mask
     if (jQuery().mask) {
          $('[data-toggle="input-mask"]').each(function (idx, obj) {
               var maskFormat = $(obj).data("maskFormat");
               var reverse = $(obj).data("reverse");
               if (reverse != null)
                    $(obj).mask(maskFormat, { 'reverse': reverse });
               else
                    $(obj).mask(maskFormat);
          });
     }

     // Date-Range-Picker
     if (jQuery().daterangepicker) {
          //date pickers ranges only
          var start = moment().subtract(29, 'days');
          var end = moment();
          var defaultRangeOptions = {
               startDate: start,
               endDate: end,
               ranges: {
                    'Today': [moment(), moment()],
                    'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                    'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
               }
          };

          $('[data-toggle="date-picker-range"]').each(function (idx, obj) {
               var objOptions = $.extend({}, defaultRangeOptions, $(obj).data());
               var target = objOptions["targetDisplay"];
               //rendering
               $(obj).daterangepicker(objOptions, function (start, end) {
                    if (target)
                         $(target).html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
               });
          });

          // Datetime and date range picker
          var defaultOptions = {
               "cancelClass": "btn-light",
               "applyButtonClasses": "btn-success"
          };

          $('[data-toggle="date-picker"]').each(function (idx, obj) {
               var objOptions = $.extend({}, defaultOptions, $(obj).data());
               $(obj).daterangepicker(objOptions);
          });
     }

     // Bootstrap Timepicker
     if (jQuery().timepicker) {
          var defaultOptions = {
               "showSeconds": true,
               "icons": {
                    "up": "mdi mdi-chevron-up",
                    "down": "mdi mdi-chevron-down"
               }
          };

          $('[data-toggle="timepicker"]').each(function (idx, obj) {
               var objOptions = $.extend({}, defaultOptions, $(obj).data());
               $(obj).timepicker(objOptions);
          });
     }

     // Bootstrap Touchspin
     if (jQuery().TouchSpin) {
          var defaultOptions = {

          };

          $('[data-toggle="touchspin"]').each(function (idx, obj) {
               var objOptions = $.extend({}, defaultOptions, $(obj).data());
               $(obj).TouchSpin(objOptions);
          });
     }

     // Bootstrap Maxlength
     if (jQuery().maxlength) {
          var defaultOptions = {
               warningClass: "badge bg-success",
               limitReachedClass: "badge bg-danger",
               separator: ' out of ',
               preText: 'You typed ',
               postText: ' chars available.',
               placement: 'bottom',
          };

          $('[data-toggle="maxlength"]').each(function (idx, obj) {
               var objOptions = $.extend({}, defaultOptions, $(obj).data());
               $(obj).maxlength(objOptions);
          });
     }
}

export function initHyperMain() {
     initComponents();
     initPortletCard();
     initMultiDropdown();
     initLeftSidebar()
     initTopbarMenu();
     initSearch();
     initfullScreenListener();
     initShowHidePassword();
     // initFormValidation();
     initFormAdvance();
}

